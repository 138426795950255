import React, {Component} from 'react';
import {Accessibility} from 'accessibility';

class AccessibilityToolbarComponent extends Component {
    componentDidMount() {
        new Accessibility({
            icon: {
                position: {
                    bottom: {size: 56, units: 'px'},
                    right: {size: 20, units: 'px'},
                    type: 'fixed'
                },
                img: ['accessible'],
                circular: true,
                fontFaceSrc: ['https://nix.co.il/static/media/material-icons.0c35d18bf06992036b69.woff2'],
            },
            session: {
                persistent: false,
            },
            hotkeys: {
                enabled: true,
                helpTitles: true,
            },
        });
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default AccessibilityToolbarComponent;
