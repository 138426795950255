import logo from './logo.svg';
import './App.css';
import CoverPage from "./components/CoverPage";
import CookieConsent from "react-cookie-consent";
import AccessibilityToolbarComponent from "./components/Accessibility";

function App() {
    return (
      <div className="App">
          <CoverPage />
          <CookieConsent
              disableStyles={true}
              contentClasses={"navbar-item has-text-white"}
              containerClasses={"navbar is-dark is-fixed-bottom"}
              overlayClasses={"navbar-menu"}
              buttonWrapperClasses={"navbar-end buttons"}
              buttonClasses={"button mt-1 mr-1 is-success"}
              declineButtonClasses={"button mt-1 mr-1 is-danger"}
              enableDeclineButton={true}
              declineButtonText={"Decline (Optional)"}
              buttonText={"Accept All"}
              flipButtons={true}
          >
              This website uses essential and analytics cookies to improve your experience.
          </CookieConsent>
          <AccessibilityToolbarComponent/>
      </div>
  );
}

export default App;

