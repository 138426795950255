import React, {useState} from 'react';

const CoverPage = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleNavbar = () => {
        setIsActive(!isActive);
    };

    return (
        <div>
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className={"navbar-item"}>
                <a href={"https://kix.co.il"} className={"button is-primary is-hidden-desktop"}>
                    <strong>Log in</strong>
                </a>
                </div>

                <a role="button" className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarNix" onClick={toggleNavbar}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id={"navbarNix"} className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <a className="navbar-item has-background-info-light">
                        <span className={"tag"}>kix.co.il</span> &nbsp;&nbsp;kix.co.il chat
                    </a>
                    <a href={"https://nix.co.il"} className="navbar-item has-background-success-light">
                        <span className={"tag"}>nix.co.il</span> &nbsp;&nbsp;nix community
                    </a>
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link has-background-warning-light">
                            Other Communities
                        </a>

                        <div className="navbar-dropdown">
                            <a className="navbar-item" href={"https://binaryvision.co.il"}>
                                <span className={"tag"}>binaryvision.co.il</span> &nbsp;&nbsp;BinaryVision
                            </a>
                            <a className="navbar-item" href={"https://www.digitalwhisper.co.il"}>
                                <span className={"tag"}>digitalwhisper.co.il</span> &nbsp;&nbsp;Digital Whisper Magazine
                            </a>
                        </div>
                    </div>

                </div>

                <div className={"navbar-end"}>
                    <div className={"navbar-item"}>
                        <div className={"buttons is-hidden-touch"}>
                            <a href={"https://status.kix.co.il"} className={"button"}>
                                <img src={"https://uptime.betterstack.com/status-badges/v1/monitor/c1kf.svg"} alt={"Availability Status"}/>
                            </a>
                            <a href={"https://kix.co.il"} className={"button is-primary"}>
                                <strong>Log in</strong>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <div className="section mt-5">
            <div className="notification has-background-success-dark has-text-success-light">
                <h1 className="title">Welcome to nix chat</h1>
                <br/>
                <p>Our servers are fully self-hosted on Google Cloud.</p>
                <p>Your data is encrypted at rest and not shared with any 3rd party vendors.</p>
                <p>Your privacy is respected.</p>
                <br/>
                <p className={"notification is-light"}><span className={"tag is-primary"}>New</span> You can now <span className={"tag is-info"}>📞 Call</span> other users directly from the platform using peer to peer communication.</p>
            </div>

            <div className="notification has-background-info-light has-text-info-dark">
                <h2 className={"title"}>Registration Instructions for Existing Users</h2>
                <p>If your email ends with @nix.co.il, please complete the verification process by following the instructions emailed to you.</p>
                <p>If your email does not end with @nix.co.il, contact an existing member to get invited.</p>
            </div>

            <div className="columns">
                <div className="column">
                    <div className="notification has-background-danger-light has-text-danger-dark">
                    <h2 className={"title"}>IRC Access</h2>
                    <figure className={"highlight"}>
                        <p>Generate your token from the Profile - Security menu and run:</p>
                        <br/>
                        <pre className={"has-background-danger-dark has-text-danger-light"}>
                            <code>
                                /server irc.nix.co.il +6697 <br />
                                /msg mattermost login nickname token=xxx
                            </code>
                        </pre>
                    </figure>
                    </div>
                </div>
                <div className="column">
                    <div className="notification has-background-link-light has-text-link-dark">
                        <h2 className={"title"}>Gaming</h2>
                        <p>We sometimes play online games like Jackbox and online card games. Notices are sent in a dedicated channel.</p>
                        <br/>
                        <a href="https://kix.co.il/nix/channels/games" className={"button is-link"}>
                            Join &nbsp;
                            <span className={"tag is-light is-link"}><strong>Lets Play</strong></span>
                        </a>
                    </div>
                </div>
                <div className="column">
                    <div className={"notification has-background-warning-light has-text-warning-dark"}>
                        <h2 className={"title"}>Password Manager</h2>
                        <p>Host your <span className={"tag is-dark is-warning"}>KeePass</span> password database on our servers for free.</p>
                        <br/>
                        <a href="https://kix.co.il/nix/channels/pwd" className={"button is-warning"}>
                            Join &nbsp;
                            <span className={"tag is-light is-warning"}><strong>pwd.kix.co.il</strong></span>
                        </a>
                    </div>

                </div>
            </div>
            <div className="notification has-background-success-light has-text-success-dark">
                <h2 className={"title"}>Need Help?</h2>
                <p>If you are unable to join, email: support@kix.co.il</p>
            </div>
        </div>
        </div>
    );
};

export default CoverPage;
